@import 'animate.css';

a {
  color: inherit;
}

#typewriter {
  position: absolute;
  top: 2vh;
  left: 53vw;
  width: 45vw;
  height: 28vh;
  font-size: 2.3vh;
  z-index: 1;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#tsparticles {
  display: block;
  width: 100vw;
  height: 30vh;
  border-bottom: 1px solid black;
}

.export {
  display: flex;
  flex: row;
}

.container {
  width: 100%;
  will-change: contents;
  min-height: 566px;
  opacity: 0;
  position: absolute;
  top: 5%;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
}

.home-container {
  position: relative;
  z-index: 1;
  width: 100vw;
  height: 70vh;

  .text-zone {
    position: absolute;
    display: flex;
    justify-content: flex-start;
    left: 25%;
    top: 20vh;
    width: 100%;
    max-height: 90%;
    flex-direction: column;
  }

  h1 {
    color: #fff;
    font-size: 53px;
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;
    position: relative;
    left: 0;

    &::before {
      content: '<h1>';
      font-family: 'La Belle Aurore';
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: -40px;
      left: -20px;
      opacity: 0.6;
    }

    &::after {
      content: '</h1>';
      font-family: 'La Belle Aurore';
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: 18px;
      margin-left: 20px;
      animation: fadeIn 1s 1.7s backwards;
      opacity: 0.6;
    }
  }
}

.sub-header {
  margin-top: 10px;
  color: #8d8d8d;
  font-size: 11px;
  font-family: sans-serif;
  letter-spacing: 3px;
  animation: fadeIn 1s 1.8s backwards;

  h2 {
    font-weight: 400;
  }
}

.flat-button {
  background: #022c43;
  color: #ffd700;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 4px;
  font-family: sans-serif;
  text-decoration: none;
  padding: 10px 18px;
  border: 1px solid #ffd700;
  margin-top: 25px;
  float: left;
  animation: fadeIn 1s 1.8s backwards;
  white-space: nowrap;

  &:hover {
    background: #ffd700;
    color: #333;
    cursor: pointer;
  }
}

.top-tags {
  bottom: auto;
  top: 35px;
}

.tags {
  color: #ffd700;
  opacity: 0.6;
  position: absolute;
  bottom: 0;
  left: 120px;
  font-size: 18px;
  font-family: 'La Belle Aurore';
}

.bottom-tags {
  bottom: 5vh;
}

.bottom-tag-html {
  margin-left: -20px;
}

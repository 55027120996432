@import 'animate.css';

.about-page,
.contact-page {
  overflow: none;
  .text-zone {
    margin-left: 10vw;
    width: 35vw;

    h1 {
      font-size: 3.8vw;
      font-family: 'Coolvetica';
      color: #ffd700;
      font-weight: 400;
      margin-top: 0;
      position: relative;
      margin-bottom: 40px;
      left: 10px;

      &::before {
        content: '<h1>';
        font-family: 'La Belle Aurore';
        font-size: 18px;
        position: absolute;
        margin-top: -10px;
        left: -10px;
        opacity: 0.6;
        line-height: 18px;
      }

      &::after {
        content: '</h1>';
        font-family: 'La Belle Aurore';
        font-size: 18px;
        line-height: 18px;
        position: absolute;
        left: -30px;
        bottom: -20px;
        margin-left: 20px;
        opacity: 0.6;
      }
    }

    p {
      margin-left: 40px;
      font-size: 15px;
      color: #fff;
      font-family: sans-serif;
      font-weight: 300;
      // min-width: fit-content;
      animation: pulse 1s;

      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }

      &:nth-of-type(2) {
        animation-delay: 1.2s;
      }

      &:nth-of-type(3) {
        animation-delay: 1.3s;
      }
    }
  }

  .text-animate-hover {
    &:hover {
      color: #fff;
    }
  }
}

.tag-cloud-container {
  position: absolute;
  right: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tagcloud {
  font-family: 'Poppins', sans-serif;  
  font-size: 30px;
  font-weight: 650;
  width: 30vw !important;
  margin-right: 12vw;
  color: #fff;
}

.tagcloud--item:hover {
  color: #ffd700;
}

.bottom-tags-about {
  position: relative;
  top: 80vh;
}

@media screen and (max-height: 900px) {
  .bottom-tags-about {
    visibility: hidden;
  }
}

@media screen and (max-height: 700px) {
  .top-tags {
    visibility: hidden;
  }
}
html {
  overflow-y: visible;
}

main {
  position: fixed;
  width: 100%;
  height: 100vh;
}

.container {
  width: 100%;
  will-change: contents;
  min-height: 566px;
  opacity: 0;
  top: 5%;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
}

.scrollable {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  will-change: transform;
}

.image-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

img {
  position: absolute;
  height: 60vh;
  width: 40vw;
  left: 10vw;
  top: 10vh;
}

.image-container:nth-of-type(1) {
  h1 {
    top: 2vh;
    left: 10vw;
    color: #ffd700;
  }
  h2 {
    color: #ffd700;
  }
  img {
    height: 40vh;
    width: 50vw;
  }
  #component-tree {
    top: 60vh;
  }
  .text-container {
    position: absolute;
    color: #fff;
    right: 5vw;
    top: 10vh;
    max-width: 30vw;
    > * {
      margin-bottom: 30px;
    }
  }
}

.image-container:nth-of-type(2) {
  h1 {
    top: 2vh;
    left: 10vw;
    color: #ffd700;
  }
  h2 {
    color: #ffd700;
  }
  .text-container {
    position: absolute;
    color: #fff;
    left: 10vw;
    top: 10vh;
    max-width: 50vw;
    > * {
      margin-bottom: 30px;
    }
  }
}

@media screen and (max-width: 650px) {
  .image-container:nth-of-type(2) .text-container {
    left: calc(10vw + 30px);
  }
}

.main-img {
  visibility: hidden;
}

.flex-container {
  // background: red;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.images-grid {
  display: flex;
  justify-content: space-between;
  height: 70vh;
  width: 52vw;
  position: absolute;
  top: 2vh;
  right: 2vw;

  img {
    width: 33%;
  }

  .main-img:nth-last-of-type(1) {
    position: relative;
    left: 0;
  }

  .main-img:nth-last-of-type(2) {
    position: relative;
    left: 0;
  }

  .main-img:nth-last-of-type(3) {
    position: relative;
    left: 0;
  }
}

.text-container:nth-of-type(2) {
  width: 35vw;
}

@media screen and (max-height: 770px) {
  .horizontal-text {
    width: 70vw;
  }
}

@media screen and (max-height: 680px) {
  .horizontal-text {
    visibility: hidden;
  }
}